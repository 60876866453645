var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import { getFilteredProducts } from 'services/api/search-filter-build-products';
import { B2B_CHANNEL, GSA_CHANNEL, NA_CONTRACT_CHANNEL, REQUEST_TYPE_QUOTE, channelArray } from 'utils/constants';
import { Form, useNavigate } from 'react-router-dom';
import { LoadingSpinner } from 'components/Shared/Loading/LoadingSpinner/LoadingSpinner';
import { PubInputField } from 'components/Shared/PubInputField/PubInputField';
import { InputFieldType } from 'models/Input/InputModels';
import { StyledButtonV2 } from 'components/Shared/StyledButtonV2/StyledButtonV2';
import { useState, useMemo, useRef, useEffect } from 'react';
import { validate } from 'services/api/product-service';
import { getUTCDateData } from 'components/ProductBuild/helper';
import { useSnackbar } from 'context/snackbar/SnackBarProvider';
import { searchParamsHelper } from '../searchParamsHelper';
import styles from './ProductBuildSearchFormV2.module.scss';
export function ProductBuildSearchFormV2() {
    var _this = this;
    var setSnackbarAlert = useSnackbar().setSnackbarAlert;
    var navigate = useNavigate();
    var indicatorSize = 10;
    var searchInputRef = useRef(null);
    var _a = useState(false), isLoading = _a[0], setIsLoading = _a[1];
    var _b = useState(0), totalNumberOfProducts = _b[0], setTotalNumberOfProducts = _b[1];
    var _c = useState(false), searchButtonClicked = _c[0], setSearchButtonClicked = _c[1];
    var _d = useState(searchParamsHelper()), searchData = _d[0], setSearchData = _d[1];
    useEffect(function () {
        searchInputRef.current.focus();
    }, [searchInputRef]);
    useEffect(function () {
        var currentHour = new Date().getUTCHours();
        if (currentHour >= 0 && currentHour < 5) {
            var data_1 = __assign({}, searchData);
            var utcData = getUTCDateData(data_1.displayDate, data_1.displayTime);
            data_1.date = utcData.date;
            data_1.time = utcData.time;
            setSearchData(function () { return data_1; });
        }
    }, [searchData]);
    var handleSearchDataValueChange = function (field, val) {
        var data = __assign({}, searchData);
        data[field] = val;
        if (field === 'date') {
            data.date = getUTCDateData(val, data.displayTime).date;
            data.displayDate = val;
        }
        else if (field === 'time') {
            var _a = getUTCDateData(data.displayDate, val), date = _a.date, time = _a.time;
            data.time = time;
            data.date = date;
            data.displayTime = val;
        }
        setSearchData(data);
    };
    var disableButton = useMemo(function () { return !searchData.text || !searchData.date || !searchData.time; }, [searchData]);
    var handle500Error = function () {
        setIsLoading(false);
        setSnackbarAlert({
            message: 'Server Error - Please report the error to the PLS support board/help desk with details',
            type: 'error',
        });
    };
    var handleSearchButtonClick = function () { return __awaiter(_this, void 0, void 0, function () {
        var text, date, time, rbv, validateRsp, productVersion, callSearchAPI, validateResBody, searchResultRsp, searchResult, formattedStartDate, splitDate, splitTime, startDateToUse, dataToPass, splitProductId, searchString;
        var _a, _b, _c, _d, _e, _f, _g;
        return __generator(this, function (_h) {
            switch (_h.label) {
                case 0:
                    setIsLoading(true);
                    setSearchButtonClicked(true);
                    text = searchData.text, date = searchData.date, time = searchData.time, rbv = searchData.rbv;
                    return [4 /*yield*/, validate({
                            productNbr: text,
                            requestType: REQUEST_TYPE_QUOTE,
                        })];
                case 1:
                    validateRsp = _h.sent();
                    if (validateRsp.status >= 500) {
                        handle500Error();
                        return [2 /*return*/, null];
                    }
                    productVersion = '';
                    callSearchAPI = true;
                    if (validateRsp.status === 200) {
                        validateResBody = validateRsp.body;
                        if (validateResBody.returnCode === '0') {
                            productVersion = (_a = validateResBody.productVersion) !== null && _a !== void 0 ? _a : '';
                            if ((rbv === GSA_CHANNEL && validateResBody.gsaInd === 'N') ||
                                (rbv === B2B_CHANNEL && validateResBody.b2bInd === 'N')) {
                                callSearchAPI = false;
                            }
                        }
                    }
                    else {
                        searchInputRef.current.focus();
                    }
                    if (!callSearchAPI) return [3 /*break*/, 3];
                    return [4 /*yield*/, getFilteredProducts(text, date, time, determineChannelVal(rbv), 0, productVersion)];
                case 2:
                    searchResultRsp = _h.sent();
                    if (searchResultRsp.status >= 500) {
                        setIsLoading(false);
                        handle500Error();
                    }
                    if (searchResultRsp.status === 200 && searchResultRsp.data) {
                        if (((_b = searchResultRsp === null || searchResultRsp === void 0 ? void 0 : searchResultRsp.data) === null || _b === void 0 ? void 0 : _b.resultsCount) === 0) {
                            setTotalNumberOfProducts((_c = searchResultRsp === null || searchResultRsp === void 0 ? void 0 : searchResultRsp.data) === null || _c === void 0 ? void 0 : _c.resultsCount);
                            setIsLoading(false);
                        }
                        else if (((_d = searchResultRsp === null || searchResultRsp === void 0 ? void 0 : searchResultRsp.data) === null || _d === void 0 ? void 0 : _d.resultsCount) === 1) {
                            searchResult = (_e = searchResultRsp.data.data) === null || _e === void 0 ? void 0 : _e[0];
                            formattedStartDate = searchResult === null || searchResult === void 0 ? void 0 : searchResult.startDate.split('T');
                            splitDate = formattedStartDate === null || formattedStartDate === void 0 ? void 0 : formattedStartDate[0].split('-');
                            splitTime = formattedStartDate === null || formattedStartDate === void 0 ? void 0 : formattedStartDate[1].split(':');
                            startDateToUse = "".concat(splitDate === null || splitDate === void 0 ? void 0 : splitDate[0], "/").concat(splitDate === null || splitDate === void 0 ? void 0 : splitDate[1], "/").concat(splitDate === null || splitDate === void 0 ? void 0 : splitDate[2], " @ ").concat(splitTime === null || splitTime === void 0 ? void 0 : splitTime[0], ":").concat(splitTime === null || splitTime === void 0 ? void 0 : splitTime[1].replace('Z', ''));
                            dataToPass = {
                                prefix: searchResult === null || searchResult === void 0 ? void 0 : searchResult.prefix,
                                name: (_f = searchResult === null || searchResult === void 0 ? void 0 : searchResult.productName) === null || _f === void 0 ? void 0 : _f[0].desc,
                                startDate: startDateToUse,
                                productId: "".concat((_g = searchResult === null || searchResult === void 0 ? void 0 : searchResult.productVersion) === null || _g === void 0 ? void 0 : _g.split('_')[1], " / ").concat(searchResult === null || searchResult === void 0 ? void 0 : searchResult.productVersion),
                            };
                            splitProductId = dataToPass.productId.split('/ ')[1].split('_');
                            searchString = searchResult === null || searchResult === void 0 ? void 0 : searchResult.prefix;
                            if (searchData.text.toLowerCase().includes(searchResult === null || searchResult === void 0 ? void 0 : searchResult.prefix.toLowerCase())) {
                                searchString = searchData.text;
                            }
                            sessionStorage.removeItem('buildGateway');
                            return [2 /*return*/, navigate("/product-build-v2/build?productNbr=".concat(searchString, "&date=").concat(date, "&time=").concat(time, "&channel=").concat(rbv, "&pid=").concat(splitProductId[0], "_").concat(splitProductId[1], "&productVersion=").concat(searchResult === null || searchResult === void 0 ? void 0 : searchResult.productVersion), {
                                    state: { item: dataToPass },
                                })];
                        }
                        else {
                            navigate("/product-build-v2/multi?productNbr=".concat(text, "&date=").concat(date, "&time=").concat(time, "&channel=").concat(rbv), {
                                state: { data: searchResultRsp },
                            });
                        }
                    }
                    return [3 /*break*/, 4];
                case 3:
                    setTotalNumberOfProducts(0);
                    searchInputRef.current.focus();
                    setIsLoading(false);
                    _h.label = 4;
                case 4: return [2 /*return*/, null];
            }
        });
    }); };
    return (_jsx(Form, { children: _jsxs(Box, __assign({ className: styles.container }, { children: [_jsx("h1", __assign({ className: styles.title }, { children: "Product Build" })), _jsx("p", __assign({ className: styles.caption }, { children: "Configure MillerKnoll products to see pricing, lead times, restrictions, and more. To begin, enter a partial product number in the search field. For historical product information, use a date relevant to your search." })), _jsx(Box, __assign({ className: styles.formContainer }, { children: _jsxs(Box, { children: [_jsx(PubInputField, { formInputRef: searchInputRef, fieldType: InputFieldType.STRING, labels: {
                                    left: 'Search by Partial Product Number',
                                    right: 'Required',
                                }, formInputName: "productNbr", fieldValue: searchData === null || searchData === void 0 ? void 0 : searchData.text, fieldValueUpdateHandler: function (e) { return handleSearchDataValueChange('text', e); }, borderFocus: !!searchData.text && searchData.text !== '' }), _jsxs(Box, __assign({ className: styles.dateAndTimeInputRow, sx: { marginTop: '24px' } }, { children: [_jsx(Box, __assign({ className: styles.spaceBetweenInputs }, { children: _jsx(PubInputField, { fieldType: InputFieldType.DATE, labels: {
                                                left: 'Date',
                                                right: 'Required',
                                            }, formInputName: "date", fieldValue: searchData === null || searchData === void 0 ? void 0 : searchData.displayDate, fieldValueUpdateHandler: function (e) { return handleSearchDataValueChange('date', e); }, borderFocus: !!(searchData === null || searchData === void 0 ? void 0 : searchData.date) }) })), _jsx(Box, __assign({ className: styles.dateTimeFieldWidth }, { children: _jsx(PubInputField, { fieldType: InputFieldType.TIME, formInputName: "time", labels: {
                                                left: 'Time',
                                                right: 'Required',
                                            }, fieldValue: searchData === null || searchData === void 0 ? void 0 : searchData.displayTime, fieldValueUpdateHandler: function (e) { return handleSearchDataValueChange('time', e); }, fieldContainerWidthOverride: "100%", borderFocus: !!(searchData === null || searchData === void 0 ? void 0 : searchData.time) }) }))] })), _jsx(Box, __assign({ sx: { marginTop: '24px' } }, { children: _jsx(PubInputField, { fieldType: InputFieldType.RADIO, fieldValue: searchData === null || searchData === void 0 ? void 0 : searchData.rbv, fieldValueUpdateHandler: function (e) { return handleSearchDataValueChange('rbv', e); }, labels: { left: 'Channel' }, fieldOptions: channelArray, fieldContainerWidthOverride: "fit-content" }) })), _jsx(Box, __assign({ sx: { textAlign: 'center', margin: '20px' } }, { children: _jsx(StyledButtonV2, { labelText: "Search", height: "47px", disabled: disableButton, buttonAction: function () { return handleSearchButtonClick(); }, width: "230px" }) }))] }) })), searchButtonClicked && (_jsx(Box, { children: !isLoading ? (_jsx(Box, { children: totalNumberOfProducts && totalNumberOfProducts > 0 ? (_jsxs("p", { children: [totalNumberOfProducts, " Products found"] })) : (_jsx("h4", { children: " No Product found based on input " })) })) : (_jsx(LoadingSpinner, { indicatorSize: indicatorSize })) }))] })) }));
}
export function determineChannelVal(val) {
    var returnVal;
    switch (val) {
        case 'Commercial':
            returnVal = NA_CONTRACT_CHANNEL;
            break;
        case 'B2B':
            returnVal = B2B_CHANNEL;
            break;
        case 'GSA':
            returnVal = GSA_CHANNEL;
            break;
        default:
            returnVal = 'Commercial';
    }
    return returnVal;
}
