var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { InputFieldType } from 'models/Input/InputModels';
import { useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import CachedIcon from '@mui/icons-material/Cached';
import { CURRENCY_ARRAY, CURRENCY_LIST, GSA_CHANNEL } from 'utils/constants';
import { usePriceListDatesWithFilter } from 'hooks/usePriceListDatesWithFilters';
import { GetListPriceRequest } from '@millerknoll/pub-api-schema';
import { dateFormatHelper } from 'utils/date-format-helper';
import { priceService } from 'services/api/price-service';
import Typography from '@mui/material/Typography';
import { StyledDialogV2 } from '../StyledDialogV2/StyledDialogV2';
import { InputFieldsContainer } from '../InputFieldsContainer/InputFieldsContainer';
import { LoadingSpinner } from '../Loading/LoadingSpinner/LoadingSpinner';
import { inputFieldsContainerHelper } from '../InputFieldsContainer/InputFieldsContainerHelper';
import { StyledButtonV2 } from '../StyledButtonV2/StyledButtonV2';
import { QuickPriceDetails } from '../QuickPriceModal/QuickPriceDetails';
import styles from './NonContractPriceModal.module.scss';
import { PubInputField } from '../PubInputField/PubInputField';
var DISCOUNT_CODE = 'discountCode';
var PRODUCT_NUMBER = 'inputProductNbr';
var LEAD_TIME = 'minLeadtime';
var CURRENCY = 'currency';
var CHANNELS = 'channels';
var PRICELIST_DATE = 'priceListDate';
var CONTRACTNUMBER = 'contractNumber';
var DATETIME = 'dateTime';
var DISCOUNT = 'discount';
var NET_PRICE = 'netPrice';
var LISTPRICE = 'listprice';
var CONTRACTNAME = 'contractName';
export function NonContractPriceModal(_a) {
    var _b, _c, _d, _e, _f;
    var _this = this;
    var _g;
    var isOpen = _a.isOpen, setIsOpen = _a.setIsOpen, productReference = _a.productReference, DiscountInfo = _a.DiscountInfo, channel = _a.channel, leadtime = _a.leadtime, validationResp = _a.validationResp, contractPriceModalType = _a.contractPriceModalType;
    var isNonContract = contractPriceModalType === 'View Price Without Contract';
    var _h = usePriceListDatesWithFilter(isNonContract), loadingCommercial = _h.loadingCommercial, loadingGSA = _h.loadingGSA, dateListCommercial = _h.dateListCommercial, dateListGSA = _h.dateListGSA;
    var _j = useState(false), submitClicked = _j[0], setSubmitClicked = _j[1];
    var _k = useState(false), refreshClicked = _k[0], setRefreshClicked = _k[1];
    var _l = useState(), currencySelected = _l[0], setCurrencySelected = _l[1];
    var _m = useState([]), dates = _m[0], setDates = _m[1];
    var _o = useState(true), isShowPriceTable = _o[0], setIsShowPriceTable = _o[1];
    var _p = useState(false), loading = _p[0], setLoading = _p[1];
    var _q = useState(), listPriceRespBody = _q[0], setListPriceRespBody = _q[1];
    var handleSubmit = function () { return __awaiter(_this, void 0, void 0, function () {
        var rsp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!isNonContract) return [3 /*break*/, 1];
                    setDates(inputFieldValues.priceListDate.value);
                    setCurrencySelected(inputFieldValues.currency.value === CURRENCY_ARRAY.CAD ? CURRENCY_LIST.CAD : CURRENCY_LIST.USD);
                    return [3 /*break*/, 3];
                case 1:
                    setDates(inputFieldValuesContract.dateTime.value);
                    setLoading(true);
                    return [4 /*yield*/, priceService.getListPrice({
                            effectiveDate: dateFormatHelper.formattedDateInyyyyMMdd(inputFieldValuesContract.dateTime.value),
                            productNumber: productReference !== null && productReference !== void 0 ? productReference : '',
                            contractNbr: inputFieldValuesContract.contractNumber.value,
                            gsaInd: inputFieldValues.channels.value === GSA_CHANNEL
                                ? GetListPriceRequest.gsaInd.Y
                                : GetListPriceRequest.gsaInd.N,
                        })];
                case 2:
                    rsp = _a.sent();
                    setListPriceRespBody(rsp.body);
                    setInputFieldValuesContractInfo(buildInputFieldValuesContractInfo(rsp.body));
                    setLoading(false);
                    _a.label = 3;
                case 3:
                    setSubmitClicked(true);
                    setNoInputChange(true);
                    return [2 /*return*/];
            }
        });
    }); };
    var buildFieldValues = function () {
        var _a;
        return (_a = {},
            _a[PRODUCT_NUMBER] = {
                value: productReference,
            },
            _a[LEAD_TIME] = {
                value: leadtime,
            },
            _a[DISCOUNT_CODE] = {
                value: DiscountInfo,
            },
            _a);
    };
    var buildInputFieldValues = function () {
        var _a, _b;
        return (__assign(__assign({}, (!submitClicked
            ? (_a = {},
                _a[CHANNELS] = {
                    value: channel === GSA_CHANNEL ? GSA_CHANNEL : 'Commercial',
                },
                _a) : {})), (_b = {}, _b[CURRENCY] = {
            value: CURRENCY_ARRAY.USD,
        }, _b[PRICELIST_DATE] = {
            value: '',
        }, _b)));
    };
    var buildInputFieldValuesContract = function () {
        var _a;
        return (_a = {},
            _a[CONTRACTNUMBER] = {
                value: '',
            },
            _a[DATETIME] = {
                value: dateFormatHelper.todaysDateFormattedLocale({ format: 'yyyy-MM-dd' }),
            },
            _a);
    };
    var buildInputFieldValuesContractInfo = function (listPrice) {
        var _a;
        var _b, _c, _d, _e;
        return (_a = {},
            _a[CONTRACTNAME] = {
                value: Number(listPrice === null || listPrice === void 0 ? void 0 : listPrice.returnCode) === 0 ? listPrice === null || listPrice === void 0 ? void 0 : listPrice.contractName : '---',
            },
            _a[PRICELIST_DATE] = {
                value: Number(listPrice === null || listPrice === void 0 ? void 0 : listPrice.returnCode) === 0
                    ? dateFormatHelper.formatDateMMddyyyyWithHyphen((_b = listPrice === null || listPrice === void 0 ? void 0 : listPrice.priceDate) !== null && _b !== void 0 ? _b : '')
                    : '---',
            },
            _a[LISTPRICE] = {
                value: Number(listPrice === null || listPrice === void 0 ? void 0 : listPrice.returnCode) === 0 ? "$".concat((_c = listPrice === null || listPrice === void 0 ? void 0 : listPrice.contractListPrice) === null || _c === void 0 ? void 0 : _c.toFixed(2)) : '---',
            },
            _a[DISCOUNT] = {
                value: Number(listPrice === null || listPrice === void 0 ? void 0 : listPrice.returnCode) === 0
                    ? appendInvoiceTypeSuffix("".concat(listPrice === null || listPrice === void 0 ? void 0 : listPrice.discountApplied, "%"), (_d = listPrice === null || listPrice === void 0 ? void 0 : listPrice.invoiceType) !== null && _d !== void 0 ? _d : '')
                    : '---',
            },
            _a[NET_PRICE] = {
                value: Number(listPrice === null || listPrice === void 0 ? void 0 : listPrice.returnCode) === 0
                    ? appendInvoiceTypeSuffix("$".concat(listPrice === null || listPrice === void 0 ? void 0 : listPrice.netPrice), (_e = listPrice === null || listPrice === void 0 ? void 0 : listPrice.invoiceType) !== null && _e !== void 0 ? _e : '')
                    : '---',
            },
            _a[CURRENCY] = {
                value: Number(listPrice === null || listPrice === void 0 ? void 0 : listPrice.returnCode) === 0 ? listPrice === null || listPrice === void 0 ? void 0 : listPrice.currencyCode : '---',
            },
            _a);
    };
    function appendInvoiceTypeSuffix(value, invoiceType) {
        var completeValue = value;
        if (invoiceType.trim().length > 0) {
            var invoiceTypeVal = "(".concat(invoiceType.charAt(0) + invoiceType.slice(1).toLowerCase(), " Sale)");
            completeValue = "".concat(completeValue, " ").concat(invoiceTypeVal);
        }
        return completeValue;
    }
    var colDefForContractPriceInfo = {
        fields: (_b = {},
            _b[CONTRACTNAME] = {
                fieldName: 'Contract Description',
                displaySeq: 1,
                hasLookup: false,
                input: {
                    type: InputFieldType.READONLY,
                },
            },
            _b[PRICELIST_DATE] = {
                fieldName: 'Price List Date',
                displaySeq: 2,
                hasLookup: false,
                input: {
                    type: InputFieldType.READONLY,
                },
            },
            _b[LISTPRICE] = {
                fieldName: 'List Price',
                displaySeq: 3,
                hasLookup: false,
                input: {
                    type: InputFieldType.READONLY,
                },
            },
            _b[DISCOUNT] = {
                fieldName: 'Discount',
                displaySeq: 4,
                hasLookup: false,
                input: {
                    type: InputFieldType.READONLY,
                },
            },
            _b[NET_PRICE] = {
                fieldName: 'Net Price',
                displaySeq: 5,
                hasLookup: false,
                input: {
                    type: InputFieldType.READONLY,
                },
            },
            _b[CURRENCY] = {
                fieldName: 'Currency',
                displaySeq: 6,
                hasLookup: false,
                input: {
                    type: InputFieldType.READONLY,
                },
            },
            _b),
    };
    var _r = useState(buildFieldValues()), fieldValues = _r[0], setFieldValues = _r[1];
    var _s = useState(buildInputFieldValues()), inputFieldValues = _s[0], setInputFieldValues = _s[1];
    var _t = useState(buildInputFieldValuesContract()), inputFieldValuesContract = _t[0], setInputFieldValuesContract = _t[1];
    var _u = useState(buildInputFieldValuesContractInfo()), inputFieldValuesContractInfo = _u[0], setInputFieldValuesContractInfo = _u[1];
    var _v = useState(true), noInputChange = _v[0], setNoInputChange = _v[1];
    var disableButton = useMemo(function () {
        return isNonContract
            ? inputFieldValues.priceListDate.value.length === 0 || noInputChange
            : inputFieldValuesContract.contractNumber.value.trim().length === 0 || noInputChange;
    }, [
        isNonContract,
        inputFieldValues.priceListDate.value.length,
        inputFieldValuesContract.contractNumber.value,
        noInputChange,
    ]);
    var colDef = {
        fields: (_c = {},
            _c[DISCOUNT_CODE] = {
                fieldName: 'Discount (Code)',
                displaySeq: 2,
                hasLookup: false,
                input: {
                    type: InputFieldType.READONLY,
                },
            },
            _c[LEAD_TIME] = {
                fieldName: 'Lead Time',
                displaySeq: 3,
                hasLookup: false,
                input: {
                    type: InputFieldType.READONLY,
                },
            },
            _c[PRODUCT_NUMBER] = {
                fieldName: 'Product Number',
                displaySeq: 1,
                hasLookup: false,
                input: {
                    type: InputFieldType.READONLY,
                },
            },
            _c),
    };
    var colDef2 = {
        preventReadOnly: true,
        isEditMode: true,
        fields: __assign(__assign({}, (!submitClicked
            ? (_d = {},
                _d[CHANNELS] = {
                    fieldName: '',
                    displaySeq: 6,
                    hasLookup: false,
                    input: {
                        type: InputFieldType.RADIO,
                        allowedFieldValues: ['GSA', 'Commercial'],
                    },
                },
                _d) : {})), (_e = {}, _e[CURRENCY] = {
            fieldName: 'Currency',
            displaySeq: 7,
            hasLookup: false,
            input: {
                type: InputFieldType.ENUM,
                allowedFieldValues: Object.values(CURRENCY_ARRAY),
            },
        }, _e[PRICELIST_DATE] = {
            fieldName: 'List Price Effective Date(s)',
            displaySeq: 4,
            hasLookup: false,
            input: {
                type: InputFieldType.ENUMCHECKBOX,
                placeholderText: 'Select Effective Date(s)',
                // eslint-disable-next-line no-nested-ternary
                allowedFieldValues: inputFieldValues.channels.value
                    ? inputFieldValues.channels.value === GSA_CHANNEL
                        ? dateListGSA
                        : dateListCommercial
                    : channel === GSA_CHANNEL
                        ? dateListGSA
                        : dateListCommercial,
            },
        }, _e)),
    };
    var colDefContractPrice = {
        preventReadOnly: true,
        isEditMode: true,
        fields: (_f = {},
            _f[CONTRACTNUMBER] = {
                fieldName: 'Contract Number',
                displaySeq: 1,
                hasLookup: false,
                input: {
                    type: InputFieldType.STRING,
                },
            },
            _f[DATETIME] = {
                fieldName: 'Date',
                displaySeq: 2,
                hasLookup: false,
                input: {
                    type: InputFieldType.DATE,
                },
            },
            _f),
    };
    var titleText = isNonContract ? 'Non-Contract Price' : 'Contract Price';
    function updateDateList(value) {
        if (value === GSA_CHANNEL)
            setInputFieldValues(inputFieldsContainerHelper.getUpdatedFieldValues(inputFieldValues, PRICELIST_DATE, dateListGSA));
        else
            setInputFieldValues(inputFieldsContainerHelper.getUpdatedFieldValues(inputFieldValues, PRICELIST_DATE, dateListCommercial));
    }
    function handleRefresh() {
        return __awaiter(this, void 0, void 0, function () {
            var rsp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!isNonContract) return [3 /*break*/, 1];
                        setDates(inputFieldValues.priceListDate.value);
                        setCurrencySelected(inputFieldValues.currency.value === CURRENCY_ARRAY.CAD ? CURRENCY_LIST.CAD : CURRENCY_LIST.USD);
                        return [3 /*break*/, 3];
                    case 1:
                        setDates(inputFieldValuesContract.dateTime.value);
                        setLoading(true);
                        return [4 /*yield*/, priceService.getListPrice({
                                effectiveDate: dateFormatHelper.todaysDateFormattedLocale({ format: 'yyyyMMdd' }, new Date(inputFieldValuesContract.dateTime.value)),
                                productNumber: productReference !== null && productReference !== void 0 ? productReference : '',
                                contractNbr: inputFieldValuesContract.contractNumber.value,
                                gsaInd: inputFieldValues.channels.value === GSA_CHANNEL
                                    ? GetListPriceRequest.gsaInd.Y
                                    : GetListPriceRequest.gsaInd.N,
                            })];
                    case 2:
                        rsp = _a.sent();
                        setListPriceRespBody(rsp.body);
                        setInputFieldValuesContractInfo(buildInputFieldValuesContractInfo(rsp.body));
                        setLoading(false);
                        _a.label = 3;
                    case 3:
                        setRefreshClicked(true);
                        setNoInputChange(true);
                        return [2 /*return*/];
                }
            });
        });
    }
    return (_jsx(StyledDialogV2, __assign({ isOpen: isOpen, setIsOpen: setIsOpen, sizeProps: { width: '90%', maxWidth: '1800px' }, rowGap: "16px", padding: "28px", title: titleText }, { children: (!loadingCommercial && !loadingGSA) || !isNonContract ? (_jsxs(Box, { children: [_jsxs(Box, __assign({ sx: {
                        display: 'flex',
                        flexDirection: 'row',
                        columnGap: submitClicked && !isNonContract ? '15%' : '',
                    } }, { children: [_jsxs(Box, __assign({ sx: {
                                // eslint-disable-next-line no-nested-ternary
                                width: submitClicked ? (isNonContract ? '100%' : '42%') : '90%',
                                display: 'flex',
                                flexDirection: submitClicked && isNonContract ? 'row' : 'column',
                                columnGap: submitClicked && dates ? '4%' : '',
                            } }, { children: [_jsx(InputFieldsContainer, { colDef: colDef, fieldValues: fieldValues, setFieldValue: function (property, value) {
                                        setFieldValues(inputFieldsContainerHelper.getUpdatedFieldValues(fieldValues, property, value));
                                    }, labelFieldNameWidth: "200px", lineHeight: "30px" }), isNonContract && (_jsx(InputFieldsContainer, { colDef: colDef2, fieldValues: inputFieldValues, setFieldValue: function (property, value) {
                                        if (inputFieldValues.priceListDate.value !== value)
                                            setNoInputChange(false);
                                        setInputFieldValues(inputFieldsContainerHelper.getUpdatedFieldValues(inputFieldValues, property, value));
                                        if (property === CHANNELS)
                                            updateDateList(value);
                                    }, labelFieldNameWidth: "200px", lineHeight: "40px" })), !isNonContract && (_jsx(InputFieldsContainer, { colDef: colDefContractPrice, fieldValues: inputFieldValuesContract, setFieldValue: function (property, value) {
                                        if (inputFieldValuesContract.contractNumber.value !== value)
                                            setNoInputChange(false);
                                        setInputFieldValuesContract(inputFieldsContainerHelper.getUpdatedFieldValues(inputFieldValuesContract, property, value));
                                    }, labelFieldNameWidth: "200px", lineHeight: "40px" })), submitClicked && (_jsx(Box, __assign({ className: styles.buttonAlign }, { children: _jsx(StyledButtonV2, { labelText: "Refresh", height: "35px", buttonAction: function () { return handleRefresh(); }, adornment: {
                                            position: 'right',
                                            icon: _jsx(CachedIcon, {}),
                                        }, disabled: disableButton }) })))] })), !isNonContract && (submitClicked || refreshClicked) && !loading && (_jsxs(Box, __assign({ className: styles.headerRightHighLight, sx: {
                                display: 'flex',
                                flexDirection: 'column',
                                width: '45%',
                                justifyContent: 'space-between',
                            } }, { children: [_jsx(Typography, __assign({ className: styles.headerText }, { children: "Price Result" })), _jsxs(Box, __assign({ className: styles.headerRightText }, { children: ["Requested List Price Effective on", ' ', dateFormatHelper.formatDateMMddyyyyWithHyphen(inputFieldValuesContract.dateTime.value)] })), _jsx(InputFieldsContainer, { colDef: colDefForContractPriceInfo, fieldValues: inputFieldValuesContractInfo, setFieldValue: function (property, value) {
                                        setInputFieldValuesContractInfo(inputFieldsContainerHelper.getUpdatedFieldValues(inputFieldValuesContractInfo, property, value));
                                    }, labelFieldNameWidth: "140px", minHeight: "30px" }), Number(listPriceRespBody === null || listPriceRespBody === void 0 ? void 0 : listPriceRespBody.returnCode) !== 0 && (_jsx(Typography, __assign({ className: styles.priceErrorText }, { children: listPriceRespBody === null || listPriceRespBody === void 0 ? void 0 : listPriceRespBody.returnMessage })))] })))] })), !submitClicked && (_jsx(Box, __assign({ className: styles.buttonAlign }, { children: _jsx(StyledButtonV2, { labelText: "Get Pricing Info", height: "35px", buttonAction: function () { return handleSubmit(); }, disabled: disableButton }) }))), (submitClicked || refreshClicked) && dates && (_jsx(Box, __assign({ sx: { marginTop: '3%' } }, { children: ((!isNonContract && Number(listPriceRespBody === null || listPriceRespBody === void 0 ? void 0 : listPriceRespBody.returnCode) === 0) ||
                        (isNonContract && dates.length >= 1)) && (_jsxs(Box, { children: [_jsxs(Box, __assign({ className: styles.priceDetailsHeader }, { children: [_jsx(Box, __assign({ className: styles.headerRightText }, { children: "Price Detail" })), _jsx(Box, __assign({ className: styles.rightSwitch }, { children: _jsx(PubInputField, { fieldType: InputFieldType.SWITCH, fieldValueUpdateHandler: function () {
                                                setIsShowPriceTable(function (prevVal) { return !prevVal; });
                                            }, switchLabel: "".concat(!isShowPriceTable ? 'Show' : 'Hide', " Price Detail"), switchWidth: 250, defaultChecked: true, noRightMargin: true }) }))] })), _jsx("hr", { className: styles.horizontalRule })] })) }))), (submitClicked || refreshClicked) && dates && currencySelected && (_jsx(Box, __assign({ className: styles.addScroll }, { children: _jsx(Box, __assign({ sx: { marginRight: '10px' } }, { children: (_g = dates
                            .sort()
                            .reverse()) === null || _g === void 0 ? void 0 : _g.map(function (date) { return (_jsx(QuickPriceDetails, { validationResp: validationResp, effectiveDate: date, productReference: productReference, showPriceTable: isShowPriceTable, modalName: "NonContractPrice", currency: currencySelected, gsaInd: inputFieldValues.channels.value === GSA_CHANNEL
                                ? GetListPriceRequest.gsaInd.Y
                                : GetListPriceRequest.gsaInd.N }, date)); }) })) }))), (submitClicked || refreshClicked) &&
                    !loading &&
                    !isNonContract &&
                    Number(listPriceRespBody === null || listPriceRespBody === void 0 ? void 0 : listPriceRespBody.returnCode) === 0 && (_jsx(Box, { children: _jsx(Box, __assign({ sx: { marginRight: '10px' } }, { children: _jsx(QuickPriceDetails, { validationResp: validationResp, effectiveDate: inputFieldValuesContract.dateTime.value, productReference: productReference, showPriceTable: isShowPriceTable, modalName: "ContractPrice", currency: "USD", noGetListPriceCall: !isNonContract, listPriceResponse: listPriceRespBody, gsaInd: inputFieldValues.channels.value === GSA_CHANNEL
                                ? GetListPriceRequest.gsaInd.Y
                                : GetListPriceRequest.gsaInd.N }) })) })), !isNonContract && loading && _jsx(LoadingSpinner, { indicatorSize: 5 })] })) : (_jsx(LoadingSpinner, { indicatorSize: 5 })) })));
}
