var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { REQUESTS_PAGE_SIZE, catTypeKey } from 'utils/constants';
import { apiService } from './api-service';
export function getCurrentCatalogs(selectedSort, signal, filterText, isShowAdHoc) {
    if (isShowAdHoc === void 0) { isShowAdHoc = false; }
    return __awaiter(this, void 0, Promise, function () {
        var _a, key, direction, sortVal, searchQueries, adhocFilter, rsp;
        var _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _a = selectedSort.split('_'), key = _a[0], direction = _a[1];
                    sortVal = key === 'relevance'
                        ? []
                        : [
                            (_b = {},
                                _b["".concat(key)] = {
                                    order: direction,
                                },
                                _b),
                        ];
                    searchQueries = [];
                    filterText === null || filterText === void 0 ? void 0 : filterText.split(' ').forEach(function (term) {
                        if (term.trim() !== '' && !Number.isNaN(+term)) {
                            searchQueries.push({
                                multi_match: {
                                    query: +term,
                                    type: 'bool_prefix',
                                    fields: [
                                        'catalogNbr',
                                        'fileNamePrefix',
                                        'leadTime',
                                        'pbIndexTypeCode',
                                        'pbProdPerPage',
                                        'hierarchy.sequence',
                                        'tableOfContents.leadTime',
                                    ],
                                },
                            });
                        }
                        searchQueries.push({
                            multi_match: {
                                query: term !== null && term !== void 0 ? term : '',
                                type: 'bool_prefix',
                                boost: 1,
                                fields: [
                                    'catalogType^10',
                                    'catalogNbr',
                                    'fileNamePrefix',
                                    'brandCode',
                                    'currency.currencySequence',
                                    'currency.currencyCode',
                                    'language.languageSequence',
                                    'language.languageCode',
                                    'mfgCode',
                                    'pbName',
                                    'pbDNDrawingInd',
                                    'scheme',
                                    'vendorCode',
                                    'versionCode',
                                    'tableOfContents.sequence',
                                    'tableOfContents.mfgCode',
                                    'tableOfContents.fileNamePrefix',
                                    'tableOfContents.currency.currencySequence',
                                    'tableOfContents.currency.currencyCode',
                                    '*.searchable',
                                ],
                                zero_terms_query: 'all',
                            },
                        });
                    });
                    adhocFilter = isShowAdHoc
                        ? {
                            bool: {
                                should: [
                                    { term: { adhocInd: true } },
                                    { term: { adhocInd: false } },
                                    { bool: { must_not: { exists: { field: 'adhocInd' } } } }
                                ]
                            }
                        }
                        : {
                            bool: {
                                should: [
                                    { term: { adhocInd: false } },
                                    { bool: { must_not: { exists: { field: 'adhocInd' } } } }
                                ]
                            }
                        };
                    return [4 /*yield*/, apiService.post('search?indexName=catalog-master', {
                            size: 1000,
                            query: {
                                bool: {
                                    must: [
                                        {
                                            range: {
                                                startDate: {
                                                    lte: 'now',
                                                },
                                            },
                                        },
                                        {
                                            range: {
                                                endDate: {
                                                    gte: 'now',
                                                },
                                            },
                                        },
                                        {
                                            bool: {
                                                must: searchQueries,
                                            },
                                        },
                                        adhocFilter,
                                    ],
                                },
                            },
                            sort: sortVal,
                        }, signal)];
                case 1:
                    rsp = _c.sent();
                    return [2 /*return*/, { body: rsp.body.data, status: rsp.status }];
            }
        });
    });
}
export function getExtractRequestsByUser(userName, showAll) {
    return __awaiter(this, void 0, Promise, function () {
        var terms, rsp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    terms = [
                        {
                            term: {
                                sk: 'METADATA',
                            },
                        },
                    ];
                    if (!showAll) {
                        terms.push({
                            term: {
                                userName: userName,
                            },
                        });
                    }
                    return [4 /*yield*/, apiService.post('search?indexName=catalog-extract-tracker', {
                            size: REQUESTS_PAGE_SIZE,
                            query: {
                                bool: {
                                    must: terms,
                                },
                            },
                            sort: {
                                requestedDate: 'desc',
                            },
                        })];
                case 1:
                    rsp = _a.sent();
                    return [2 /*return*/, { body: rsp.body.data, status: rsp.status, total: rsp.body.resultsCount }];
            }
        });
    });
}
export function getExtractRequestOutputById(requestId) {
    var _a;
    return __awaiter(this, void 0, Promise, function () {
        var terms, rsp;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    terms = [
                        {
                            term: {
                                sk: 'OUTPUTS',
                            },
                        },
                        {
                            term: {
                                requestId: requestId,
                            },
                        },
                    ];
                    return [4 /*yield*/, apiService.post('search?indexName=catalog-extract-tracker', {
                            size: 1000,
                            query: {
                                bool: {
                                    must: terms,
                                },
                            },
                        })];
                case 1:
                    rsp = _b.sent();
                    return [2 /*return*/, { body: (_a = rsp.body.data) === null || _a === void 0 ? void 0 : _a[0], status: rsp.status, total: rsp.body.resultsCount }];
            }
        });
    });
}
export function getTrackerForPartialKey(requestId, skPrefix) {
    return __awaiter(this, void 0, Promise, function () {
        var rsp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, apiService.post('search?indexName=catalog-extract-tracker', {
                        size: 1000,
                        sort: [
                            {
                                requestedDate: {
                                    order: 'asc',
                                },
                            },
                        ],
                        query: {
                            bool: {
                                must: [
                                    {
                                        term: {
                                            requestId: {
                                                value: requestId,
                                            },
                                        },
                                    },
                                    {
                                        prefix: {
                                            sk: {
                                                value: skPrefix,
                                            },
                                        },
                                    },
                                ],
                            },
                        },
                    })];
                case 1:
                    rsp = _a.sent();
                    return [2 /*return*/, { body: rsp.body.data, status: rsp.status, total: rsp.body.resultsCount }];
            }
        });
    });
}
export function getPreSignedFileUrls(paths) {
    return __awaiter(this, void 0, void 0, function () {
        var rsp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, apiService.post('catalog/get-files', { files: paths })];
                case 1:
                    rsp = _a.sent();
                    return [2 /*return*/, rsp];
            }
        });
    });
}
export function getProductsByQuery(leadTime, effectiveDate, channel, selectedCatType, includeNonPrmtbl, signal, queryText) {
    return __awaiter(this, void 0, void 0, function () {
        var numberSearchText, formattedEffectiveDate, promotableType, mustConditions, rsp;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    numberSearchText = queryText && queryText.trim() !== '' && !Number.isNaN(+queryText);
                    formattedEffectiveDate = effectiveDate.replaceAll('/', '-');
                    promotableType = catTypeKey[selectedCatType];
                    mustConditions = [
                        {
                            range: {
                                startDate: {
                                    lte: formattedEffectiveDate,
                                },
                            },
                        },
                        {
                            range: {
                                endDate: {
                                    gte: formattedEffectiveDate,
                                },
                            },
                        },
                        {
                            range: {
                                minLeadtime: {
                                    lte: leadTime,
                                },
                            },
                        },
                        {
                            terms: {
                                'channels.code': [channel],
                            },
                        },
                    ];
                    if (!includeNonPrmtbl) {
                        mustConditions.push({
                            term: (_a = {},
                                _a["channels.".concat(promotableType)] = {
                                    value: true,
                                },
                                _a),
                        });
                    }
                    return [4 /*yield*/, apiService
                            .post('search?indexName=product-version', {
                            size: 100,
                            query: {
                                bool: {
                                    must: __spreadArray(__spreadArray([], mustConditions, true), [
                                        {
                                            bool: {
                                                must_not: [
                                                    {
                                                        term: {
                                                            productClass: {
                                                                value: 'WORKORDER',
                                                            },
                                                        },
                                                    },
                                                    {
                                                        term: {
                                                            productClass: {
                                                                value: 'OPTIONS',
                                                            },
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            bool: {
                                                should: [
                                                    numberSearchText && {
                                                        multi_match: {
                                                            query: +queryText,
                                                            type: 'bool_prefix',
                                                            fields: ['minLeadtime'],
                                                        },
                                                    },
                                                    {
                                                        multi_match: {
                                                            query: queryText !== null && queryText !== void 0 ? queryText : '',
                                                            type: 'bool_prefix',
                                                            fields: [
                                                                'assets.fileNm.searchable',
                                                                'assets.imgTp.searchable',
                                                                'assets.lyrNm.searchable',
                                                                'assets.mdlNm.searchable',
                                                                'assets.type.',
                                                                'assets.url.',
                                                                'baseProducts.baseProductNbr.',
                                                                'brandCode.',
                                                                'businessUnitCode.',
                                                                'channels.code.',
                                                                'channels.desc.searchable',
                                                                'deviationDesc.searchable',
                                                                'discountCode.searchable',
                                                                'discountDesc.searchable',
                                                                'engStatus.searchable',
                                                                'features.id.searchable',
                                                                'restrictions.bulletinNbr',
                                                                'restrictions.comment.searchable',
                                                                'restrictions.restrictionId',
                                                                'genericCode',
                                                                'legacyProductId',
                                                                'line.code',
                                                                'prefix',
                                                                'priceMatrixId',
                                                                'productClass',
                                                                'productFunctionDesc.desc.searchable',
                                                                'productFunctionDesc.shortDesc.searchable',
                                                                'productId',
                                                                'productName.desc.searchable',
                                                                'productName.shortDesc.searchable',
                                                                'productType',
                                                                'productVersion',
                                                                'restrictions.bulletinNbr',
                                                                'restrictions.comment.searchable',
                                                                'restrictions.reasonCode',
                                                                'restrictions.restrictionId',
                                                                'royalty.desc.searchable',
                                                                'royalty.id.searchable',
                                                                'standardProdRef.searchable',
                                                            ],
                                                            zero_terms_query: 'all',
                                                        },
                                                    },
                                                ],
                                                minimum_should_match: 1,
                                            },
                                        },
                                    ], false),
                                },
                            },
                            _source: [
                                'productVersion',
                                'productName.desc',
                                'prefix',
                                'startDate',
                                'endDate',
                                'minLeadtime',
                                'channels',
                                'hierarchy',
                                'productId',
                            ],
                        }, signal)
                            .catch(function (error) { return ({ body: [], status: error.code }); })];
                case 1:
                    rsp = _b.sent();
                    return [2 /*return*/, { body: rsp.body.data, status: rsp.status }];
            }
        });
    });
}
