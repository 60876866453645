var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable no-underscore-dangle */
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import Switch from '@mui/material/Switch';
import { useCurrentCatalogs } from 'hooks/useCurrentCatalogs';
import { Loading } from 'components/Shared/Loading/AppLoading/Loading/Loading';
import { useEffect, useMemo, useRef, useState } from 'react';
import TextField from '@mui/material/TextField';
import { debounce } from '@mui/material/utils';
import { StyledButton } from 'components/Shared/StyledComponents/StyledButton';
import { Link } from 'react-router-dom';
import { CatalogsTable } from 'components/Shared/CatalogsTable/CatalogsTable';
import { CATALOG_SCOPES } from 'utils/constants';
import { SecuredLink } from 'components/Shared/SecuredLink/SecuredLink';
import { catalogHelper } from 'utils/catalog-helper';
import { SortDropdown } from 'components/Shared/SortDropdown/SortDropdown';
import { PageContainer } from 'components/Shared/PageContainer/PageContainer';
import styles from './Catalogs.module.scss';
export function Catalogs() {
    var _a;
    var _b = useState(), selectedCatalogs = _b[0], setSelectedCatalogs = _b[1];
    var _c = useState(0), totalSelected = _c[0], setTotalSelected = _c[1];
    var _d = useState(''), filterText = _d[0], setFilterText = _d[1];
    var catalogSortValues = useState(catalogHelper.getCatalogSortValues())[0];
    var _e = useState("".concat(catalogSortValues[0].key, "_").concat(catalogSortValues[0].direction)), selectedSort = _e[0], setSelectedSort = _e[1];
    var _f = useState(false), isShowAdHoc = _f[0], setIsShowAdHoc = _f[1];
    var controllerRef = useRef();
    var _g = useCurrentCatalogs(selectedSort, (_a = controllerRef.current) === null || _a === void 0 ? void 0 : _a.signal, filterText, isShowAdHoc), loading = _g.loading, catalogs = _g.body;
    var handleSelect = function (key, catalog) {
        var _a;
        var updated = __assign({}, selectedCatalogs);
        if ((selectedCatalogs === null || selectedCatalogs === void 0 ? void 0 : selectedCatalogs[key]) === undefined) {
            setTotalSelected(totalSelected + 1);
            updated = __assign(__assign({}, selectedCatalogs), (_a = {}, _a[key] = catalog, _a));
        }
        else {
            setTotalSelected(totalSelected - 1);
            delete updated[key];
        }
        setSelectedCatalogs(updated);
        localStorage.setItem('selected', JSON.stringify(updated));
    };
    var handleSelectAll = function () {
        var _a;
        var catalogValues = Object.entries(selectedCatalogs !== null && selectedCatalogs !== void 0 ? selectedCatalogs : {});
        if ((catalogValues === null || catalogValues === void 0 ? void 0 : catalogValues.length) === (catalogs === null || catalogs === void 0 ? void 0 : catalogs.length)) {
            handleClearSelections();
        }
        else {
            var allCatalogs_1 = {};
            catalogs.forEach(function (catalog) {
                allCatalogs_1[catalog.key._id] = catalog;
            });
            setTotalSelected((_a = catalogs === null || catalogs === void 0 ? void 0 : catalogs.length) !== null && _a !== void 0 ? _a : 0);
            setSelectedCatalogs(allCatalogs_1);
            localStorage.setItem('selected', JSON.stringify(allCatalogs_1));
        }
    };
    var handleClearSelections = function () {
        setTotalSelected(0);
        setSelectedCatalogs({});
        localStorage.setItem('selected', JSON.stringify({}));
    };
    var handleSortSelect = function (field, direction) {
        var newSelectedSort = "".concat(field, "_").concat(direction);
        if (newSelectedSort !== selectedSort) {
            setSelectedSort(newSelectedSort);
        }
    };
    useEffect(function () {
        var storedSelectedCatalogs = localStorage.getItem('selected');
        if (storedSelectedCatalogs && storedSelectedCatalogs !== 'undefined') {
            var parsed = JSON.parse(storedSelectedCatalogs);
            setTotalSelected(Object.keys(parsed !== null && parsed !== void 0 ? parsed : {}).length);
            setSelectedCatalogs(parsed);
        }
    }, []);
    var handleSearchInputDebounced = useMemo(function () {
        return debounce(function (text) {
            if (controllerRef.current) {
                controllerRef.current.abort();
            }
            var controller = new AbortController();
            controllerRef.current = controller;
            setFilterText(text);
        }, 350);
    }, []);
    var handleToggleAdhoc = function (event) {
        setIsShowAdHoc(event.target.checked);
    };
    var filteredCatalogs = isShowAdHoc
        ? catalogs.filter(function (catalog) { var _a; return ((_a = catalog.body) === null || _a === void 0 ? void 0 : _a.adhocInd) === true; })
        : catalogs.filter(function (catalog) { var _a; return ((_a = catalog.body) === null || _a === void 0 ? void 0 : _a.adhocInd) !== true; });
    return (_jsxs(PageContainer, { children: [_jsxs(Box, __assign({ className: styles.titleExtractContainer }, { children: [_jsx(Typography, __assign({ variant: "h1" }, { children: "Catalogs" })), _jsxs(Box, __assign({ className: styles.buttonContainer }, { children: [_jsx(SecuredLink, { scopes: CATALOG_SCOPES, labelText: "Extract", urlPath: "/catalogs/run", disabled: totalSelected === 0 }), _jsx(Link, __assign({ className: styles.requestsLink, to: "/catalogs/requests" }, { children: "Requests" })), _jsx(SecuredLink, { scopes: CATALOG_SCOPES, labelText: "Ad Hoc", urlPath: "/catalogs/adhoc" })] }))] })), _jsx(TextField, { size: "small", label: "Enter text to search Catalogs (Hierarchy, Description, SIF, PB, CET Filename, Channel, Lead Time, ...)", onInput: function (event) {
                    var text = event.target.value;
                    if (text.length > 1 || text === '') {
                        handleSearchInputDebounced(text);
                    }
                } }), loading ? (_jsx(Loading, { className: styles.loading })) : (_jsxs(_Fragment, { children: [_jsxs(Box, __assign({ className: styles.controls }, { children: [_jsxs(Box, __assign({ className: styles.selectAllContainer, sx: {
                                    display: (catalogs === null || catalogs === void 0 ? void 0 : catalogs.length) === 0 || (catalogs === null || catalogs === void 0 ? void 0 : catalogs.length) > 500 ? 'none !important' : 'flex',
                                } }, { children: [_jsx(FormControl, { children: _jsx(FormGroup, __assign({ onChange: handleSelectAll }, { children: _jsx(FormControlLabel, { label: "", control: _jsx(Checkbox, { checked: totalSelected === (catalogs === null || catalogs === void 0 ? void 0 : catalogs.length) }) }) })) }), _jsx(Typography, __assign({ className: styles.selectAllContainerText }, { children: "Select All/None" }))] })), _jsxs(Box, __assign({ className: styles.sortSelectedContainer }, { children: [_jsx(SortDropdown, { handleSelect: handleSortSelect, sortValues: catalogSortValues, selectedSort: selectedSort }), _jsxs(Box, __assign({ className: styles.selectedContainer }, { children: [_jsx(Typography, __assign({ className: styles.selectedContainerText }, { children: "Selected: ".concat(totalSelected) })), _jsx(StyledButton, { label: "Clear Selections", widthFromProps: 140, disabledFromProps: totalSelected === 0, handleClick: function () { return handleClearSelections(); } })] })), _jsx(Box, __assign({ className: styles.rightSwitch }, { children: _jsx(FormControlLabel, { control: _jsx(Switch, { checked: isShowAdHoc, onChange: handleToggleAdhoc, name: "AdHoc" }), label: "".concat(isShowAdHoc ? 'Hide' : 'Show', " AdHoc") }) }))] }))] })), _jsx(CatalogsTable, { catalogs: filteredCatalogs !== null && filteredCatalogs !== void 0 ? filteredCatalogs : [], selectedCatalogs: selectedCatalogs !== null && selectedCatalogs !== void 0 ? selectedCatalogs : {}, showStartDateColumn: true, handleSelect: function (k, cat) { return handleSelect(k, cat); } })] }))] }));
}
